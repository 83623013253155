<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>
        <!--경기보기 Comp-->
        <div class="content_section">
            <div class="content">
                <sub-title>
                   Sports
                </sub-title>
                <sports-normal-comp :game-list="games" :display-position="displayPosition"
                                    @moreGames="moreGames"></sports-normal-comp>
            </div>
        </div>


        <!--오른쪽 메뉴-->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--푸트메뉴-->
        <foot-comp></foot-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <!--모바일모드일경우 푸트 메뉴 [리그$종목 ,베팅카드]-->
<!--        <mobile-sports-foot-menu-comp></mobile-sports-foot-menu-comp>-->
        <bet-cart-icon-sports-comp></bet-cart-icon-sports-comp>
        <sports-leagues-mobile-comp></sports-leagues-mobile-comp>

    </div>
</template>

<script>
    import sportsConst from '../../common/sportsConst'
    import TopbarComp from "../../components/TopbarComp";
    import SportsNormalComp from "../../components/sports/SportsNormalComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {
        getSportsGames,
        getSportsMoreGames
    } from "../../network/sportsRequest";
    import {
        RECEIVE_CHECKED_GAME_TYPE,
        RECEIVE_CHECKED_LEAUGE_DELALL,  RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING,
        RECEIVE_SPORTS_CART_ITEM_DEL_ALL
    } from "../../store/mutation-types";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import RightBarComp from "../../components/RightBarComp";
    import MobileSportsFootMenuComp from "../../components/mobile/MobileSportsFootMenuComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import FootComp from "../../components/FootComp";
    import {mapGetters} from "vuex";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SubTitle from "../../components/SubTitle";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import BetCartIconSportsComp from "../../components/BetCartIconSportsComp";
    import SportsLeaguesMobileComp from "../../components/SportsLeaguesMobileComp";

    export default {
        name: "SportsOriginal",
        mixins:[postionMixin],
        components: {
            SportsLeaguesMobileComp,
            BetCartIconSportsComp,
            RightButtonsComp,
            UserInfoComp,
            SubTitle,
            RightBarBannerComp,
            FootComp,
            SportsLeftBarComp,
            LeftBarComp,
            MobileSportsFootMenuComp,
            RightBarComp,
            ScrollTopComp, SportsBetCartComp, SportsNormalComp, TopbarComp
        },
        data() {
            return {
                displayPosition: sportsConst.GAME_DISPLAYPOSITION_1x2,
                games: [],
                leagueId: 0,
                gameType: 0,
                mGames: [],//승무패 more게임클릭시 핸디,오버언더게임,
                position:"스포츠",
            }
        },
        methods: {
            getSportsGames() {
                //배팅카드내역 삭제
                this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getSportsGames(this.displayPosition).then(res => {
                    if (res.data.success) {
                        this.games = res.data.data
                        this.games.forEach(g => {
                            try {
                                this.$set(g, 'isShow', true)
                                this.$set(g, 'selectWay', sportsConst.WAY_NONE)
                                this.$set(g, 'selectOdd', 1)
                                this.$set(g, 'moreGames', [])
                                this.$set(g, 'handicapGames', [])
                                this.$set(g, 'overunderGames', [])
                                this.$set(g, 'showMoreGames', false)

                                //배당 소수점 두자리 설정 1.8 => 1.80
                                g.homeTeamOdds = g.homeTeamOdds.toFixed(2);
                                g.awayTeamOdds = g.awayTeamOdds.toFixed(2);
                                if (g.kind === sportsConst.GAME_KIND_1X2 && g.drawOdds > 0) {
                                    g.drawOdds = g.drawOdds.toFixed(2);
                                }
                                this.settingGamesShow(g)
                            }catch (e) {

                            }
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            //Gametype,League선택에 의한 경기 보여주기
            settingGamesShow(g) {
                //타입이 all일경우 전부 보여줌
                if (this.getSelectedGameType === sportsConst.GAME_TYPE_ALL) {
                    g.isShow = true
                } else {
                    //해당타입만 보여줌
                    g.isShow = g.league.gameTypeId === this.getSelectedGameType
                }
                //리그가 선택되였을경우
                if (this.getSelectedLeagueList.length > 0 && this.getSelectedLeagueList.findIndex((le) => {
                    return g.leagueId === le.id
                }) === -1) {
                    g.isShow = false
                }
                if (this.displayPosition === sportsConst.GAME_DISPLAYPOSITION_1x2) {
                    if (g.kind !== sportsConst.GAME_KIND_1X2) {
                        g.isShow = false;
                    }
                }

            },
            moreGames(game) {
                if (game.handicapGames.length > 0 || game.overunderGames.length > 0) {
                    game.showMoreGames = !game.showMoreGames;
                } else {
                    game.showMoreGames = true
                    this.$store.commit(RECEIVE_SHOW_LOADING)
                    getSportsMoreGames(game.groupCode).then(res=>{

                        if (res.data.success) {
                            let h = res.data.data.handicap
                            let handicap = []
                            h.forEach(rmg => {
                                try{
                                    this.$set(rmg, 'isbonus', sportsConst.NOT)
                                    rmg.homeTeamOdds = rmg.homeTeamOdds.toFixed(2);
                                    rmg.awayTeamOdds = rmg.awayTeamOdds.toFixed(2);
                                }catch (e) {
                                    console.log(e)
                                }
                                handicap.push(rmg)
                            })
                            game.handicapGames = handicap

                            let ou = res.data.data.overunder
                            let overunder = []
                            ou.forEach(rmg => {
                                try{
                                    this.$set(rmg, 'isbonus', sportsConst.NOT)
                                    rmg.homeTeamOdds = rmg.homeTeamOdds.toFixed(2);
                                    rmg.awayTeamOdds = rmg.awayTeamOdds.toFixed(2);
                                }catch (e) {
                                    console.log(e)
                                }
                                overunder.push(rmg)
                            })
                            game.overunderGames = overunder
                        }
                        this.$store.commit(RECEIVE_HIDE_LOADING)

                    })
                }

            }
        },
        created() {
            this.$store.state.displayPostion =  sportsConst.GAME_DISPLAYPOSITION_1x2
            this.getSportsGames();

        },
        computed: {
            ...mapGetters({
                'getSelectedGameType': 'getSelectedGameType',
                'getSelectedLeagueList': 'getSelectedLeagueList',
            }),
        },
        watch: {
            "$store.state.displayPostion": {
                deep: true,
                handler: function (newVal) {
                    this.displayPosition = newVal
                    this.$store.commit(RECEIVE_CHECKED_LEAUGE_DELALL);
                    this.$store.commit(RECEIVE_CHECKED_GAME_TYPE, sportsConst.GAME_TYPE_ALL)
                    this.getSportsGames();
                }
            },
            "$store.state.selectedGameType": {
                deep: true,
                handler: function (newVal) {
                    this.games.forEach(g => {
                        this.settingGamesShow(g);
                    })
                }
            },
            "$store.state.selectedLeagueList": {
                deep: true,
                handler: function (newVal) {
                    this.games.forEach(g => {
                        this.settingGamesShow(g);
                    })
                }
            }
        },

    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
</style>
